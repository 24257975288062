import * as Sentry from '@sentry/vue';
import { useAuthStore } from '~~/store/auth';
import { useEventStore } from '~~/store/event';

export default defineNuxtPlugin(async (nuxtApp) => {
  // return 
  //check if http or local return
  let isLocal = window.location.protocol == 'http:'
  if (isLocal) {
    return
  }

  const { public: { sentry } } = useRuntimeConfig()
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: "my-project-name@2.3.12",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })


  let eventStore = useEventStore()
  watch(() => eventStore.eventId, (a, b) => {
    Sentry.setUser({
      id: eventStore.eventId,
      username: `${useAuthStore().userId}`,
    })
  })

  nuxtApp.provide('Sentry', Sentry)
  nuxtApp.vueApp.config.errorHandler = (err: any, vm, info) => {
    Sentry.captureMessage(err);
  }
})


function mockError() {
  return new Promise((resolve, reject) => {

    reject('mock error')
  })
}

