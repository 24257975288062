export function useTemplateZooms({
  stage,
  groupFrame,
  groupClipped,
  template
}) {



  function initZoomTools() {
    let removeBg = template.removebg ? true : false
    let fixImageToFrameSize = removeBg ? false : true;

    const clonedGuestImage = stage.findOne('#clonedGuestImage')
    const defaultX = clonedGuestImage.x() || 0
    const defaultY = clonedGuestImage.y() || 0
    const defaultScaleX = clonedGuestImage.scaleX() || 1
    const defaultScaleY = clonedGuestImage.scaleY() || 1

    let guestContainer = stage.findOne('#guest');

    let frameAreaImage = groupFrame.children.find((el) => el.attrs.id === 'FrameArea')
    let frameContainer = new Konva.Rect({
      ...frameAreaImage.getAttrs(),
      // fill: 'red',
      // opacity: 0.5,
    })
    
    // // for test
    // const layers = new Konva.Layer()
    // layers.add(frameContainer)
    // stage.add(layers)
    // frameContainer.listening(false)
    // frameContainer.moveToTop()
    


    function canZoomOut(number = 0.95) {
      if (fixImageToFrameSize === false) {
        return true
      }
  
      const width = guestContainer.width() * guestContainer.scaleX()
      const height = guestContainer.height() * guestContainer.scaleY()

      let frameWidth = frameContainer.width() * frameAreaImage.scaleX()
      let frameHeight = frameContainer.height() * frameAreaImage.scaleY()

      let newWidth =  width * number;
      let newHeight = height * number;
    
      // วัดจากทางขวา และ ล่าง
      if (guestContainer.y() + newHeight < frameContainer.y() + frameHeight || guestContainer.x() + newWidth < frameContainer.x() + frameWidth) {
        return false
      }
      
      // if (newHeight < frameHeight || newWidth < frameWidth) {
      //   return false
      // }
      return true
    }
  
  
    const scrollNumber = 20
    function canScroll(type) {
      if (fixImageToFrameSize === false) {
        return true
      }

      const x = guestContainer.x()
      const y = guestContainer.y()
      const width = guestContainer.width() * guestContainer.scaleX()
      const height = guestContainer.height() * guestContainer.scaleY()

      const frameWidth = frameContainer.width() * frameContainer.scaleX()
      const frameHeight = frameContainer.height() * frameContainer.scaleY()
      const frameX = frameContainer.x()
      const frameY = frameContainer.y()
  
      if(type === 'left' && x + width - scrollNumber <= Math.ceil(frameX + frameWidth)) {
        return false
      }
  
      if(type === 'right') {
        return x + scrollNumber < frameX
      }
  
      if(type === 'top' && y + height - scrollNumber <= Math.ceil(frameY + frameHeight)) {
        return false
      }
  
      if(type === 'bottom') {
        return y + scrollNumber < frameY 
      }
  
      return true
    }
    
    return {
      // ------ Zoom -------
      zoomIn: function () {
        if(!clonedGuestImage) {
          return
        }
        let scaleCurrent = clonedGuestImage.scale()
        clonedGuestImage.scale({
          x: scaleCurrent.x * 1.07,
          y: scaleCurrent.y * 1.07
        })
        guestContainer.scale({
          x: scaleCurrent.x * 1.07,
          y: scaleCurrent.y * 1.07
        })
    

        // imageToCenter(guestContainer, frameContainer)
        setGuestContainer(guestContainer, clonedGuestImage)
      },
      zoomOut: function () {
        if(!clonedGuestImage) {
          return
        }
        let res = canZoomOut(0.93)
        if (res == false) {
          console.log('cannot zoom out more');
          return false
        }

        let scaleCurrent = clonedGuestImage.scale()
        clonedGuestImage.scale({
          x: scaleCurrent.x * 0.93,
          y: scaleCurrent.y * 0.93
        })
        guestContainer.scale({
          x: scaleCurrent.x * 0.93,
          y: scaleCurrent.y * 0.93
        })
        // imageToCenter(guestContainer, frameContainer)
        setGuestContainer(guestContainer, clonedGuestImage)
      },
      zoomReset: function () {
        if(!clonedGuestImage) {
          return
        }

        console.log(defaultX, defaultY)
        clonedGuestImage.scale({
          x: defaultScaleX,
          y: defaultScaleY
        })
        clonedGuestImage.setAttrs({
          x: defaultX,
          y: defaultY
        })

        guestContainer.scale({
          x: defaultScaleX,
          y: defaultScaleY
        })
        guestContainer.setAttrs({
          x: defaultX,
          y: defaultY
        })


        // imageToCenter(guestContainer, frameContainer)
        setGuestContainer(guestContainer, clonedGuestImage)
      },
      rotate: function (deg = 90) { },

      // ------ scroll -------
      scrollLeft: function () {
        if(!clonedGuestImage) {
          return
        }
        const res = canScroll('left')
        if (res == false) {
          console.log('cannot scroll left more');
          return false
        }

        clonedGuestImage.setAttrs({
          x: clonedGuestImage.x() - scrollNumber,
          y: clonedGuestImage.y(),
        })
        guestContainer.setAttrs({
          x: clonedGuestImage.x() - scrollNumber,
          y: clonedGuestImage.y(),
        })
        setGuestContainer(guestContainer, clonedGuestImage)
      },
      scrollRight: function () {
        if(!clonedGuestImage) {
          return
        }
        const res = canScroll('right')
        if (res == false) {
          console.log('cannot scroll right more');
          return false
        }

        clonedGuestImage.setAttrs({
          x: clonedGuestImage.x() + scrollNumber,
          y: clonedGuestImage.y(),
        })
        guestContainer.setAttrs({
          x: clonedGuestImage.x() + scrollNumber,
          y: clonedGuestImage.y(),
        })
        setGuestContainer(guestContainer, clonedGuestImage)
      },
      scrollTop: function () {
        if(!clonedGuestImage) {
          return
        }
        const res = canScroll('top')
        if (res == false) {
          console.log('cannot scroll top more');
          return false
        }

        clonedGuestImage.setAttrs({
          x: clonedGuestImage.x(),
          y: clonedGuestImage.y() - scrollNumber,
        })
        guestContainer.setAttrs({
          x: clonedGuestImage.x(),
          y: clonedGuestImage.y() - scrollNumber,
        })
        setGuestContainer(guestContainer, clonedGuestImage)
      },
      scrollBottom: function () {
        if(!clonedGuestImage) {
          return
        }
        const res = canScroll('bottom')
        if (res == false) {
          console.log('cannot scroll bottom more');
          return false
        }

        clonedGuestImage.setAttrs({
          x: clonedGuestImage.x(),
          y: clonedGuestImage.y() + scrollNumber,
        })
        guestContainer.setAttrs({
          x: clonedGuestImage.x(),
          y: clonedGuestImage.y() + scrollNumber,
        })
        setGuestContainer(guestContainer, clonedGuestImage)
      },
    }
  }

  // utils
  function imageToCenter(imageContainer:any, frameContainer:any) {
    const imageWidth = imageContainer.width() * imageContainer.scaleX()
    const imageHeight = imageContainer.height() * imageContainer.scaleY()

    const frameX = frameContainer.x()
    const frameY = frameContainer.y()
    const frameWidth = frameContainer.width() * frameContainer.scaleX()
    const frameHeight = frameContainer.height() * frameContainer.scaleY()
    
    let newX = frameX + (frameWidth - imageWidth) / 2
    let newY = frameY + (frameHeight - imageHeight) / 2

    imageContainer.setAttrs({
      x: newX,
      y: newY
    })



    return imageContainer
  }


  function setGuestContainer(imageContainer: any, cloneImageContainer: any) {
    imageContainer.setAttrs({
      x: cloneImageContainer.x(),
      y: cloneImageContainer.y(),
      scaleX: 1,
      scaleY: 1,
      width: cloneImageContainer.width() * cloneImageContainer.scaleX(),
      height: cloneImageContainer.height() * cloneImageContainer.scaleY(),
    });
  }


  return {
    initZoomTools,
  }
}