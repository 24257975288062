export const appHead = {"meta":[{"charset":"utf-8"},{"hid":"description","name":"description","content":""},{"property":"og:title","content":"เขียนคำอวยพรด้วยรูป"},{"property":"og:description","content":"เขียนคำอวยพรด้วยรูป"},{"property":"og:image","content":"https://www.photowish.com/images/logo01.png"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/pw-favicon.png"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":true},{"onload":"this.onload=null;this.rel='stylesheet'","rel":"preload","as":"style","href":"https://fonts.googleapis.com/css2?family=Prompt:ital@0;1&&display=swap"},{"onload":"this.onload=null;this.rel='stylesheet'","rel":"preload","as":"style","href":"https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai&family=Dancing+Script&display=swap"},{"onload":"this.onload=null;this.rel='stylesheet'","rel":"preload","as":"style","href":"https://fonts.googleapis.com/css2?family=Cinzel&family=Noto+Sans+Thai&display=swap"}],"style":[],"script":[{"src":"/js/checkDotQuestion.js","crossorigin":"anonymous","type":"text/javascript"},{"src":"/js/safari.js","crossorigin":"anonymous","type":"text/javascript"},{"src":"/js/checkApp.js","crossorigin":"anonymous","type":"text/javascript"},{"src":"https://kit.fontawesome.com/dcadb9a5ea.js","crossorigin":"anonymous","type":"text/javascript"},{"src":"https://static.line-scdn.net/liff/edge/versions/2.23.1/sdk.js"},{"src":"https://unpkg.com/konva@9/konva.min.js"},{"src":"https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"}],"noscript":[],"title":"เขียนคำอวยพรด้วยรูป","viewport":"width=device-width, initial-scale=1","charset":"utf-8"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false