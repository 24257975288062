import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'
import { filterTemplate } from '~~/utils/filterTemplate'

import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'

import _map from 'lodash/map.js'
import _find from 'lodash/find.js'
import _forEach from 'lodash/forEach.js'
import _concat from 'lodash/concat.js'
import _sample from 'lodash/sample.js'
import _cloneDeep from 'lodash/cloneDeep.js'
import _uniq from 'lodash/uniq.js'
import _uniqBy from 'lodash/uniqBy.js'
import _includes from 'lodash/includes.js'
import _filter from 'lodash/filter.js'
import _take from 'lodash/take.js'
import { useEventStore } from './event'
import { useWishStore } from './wish'
import FontFaceObserver  from 'fontfaceobserver'

import delay from '~/utils/delay'
import { useTemplate } from '../composables/useTemplate'

export const useTemplateStore = defineStore({
  id: 'template',
  state: () => {
    return {
      templateUsedIds: [] as Array<string>, //[ $templateId... ]
      templateList: [],
      templateId: null as any,
      imageAttrs : null as any,
      template_1: null as any,
      template_2: null as any,
      template_3: null as any,
      templateUrlList: [],
    }
  },
  actions: {
    async main() { 
      await this.fetchTemplateList()
      this.generateRandomTemplates()
    },

    fetchTemplateList() {
      const wishStore = useWishStore()
      const myWishes = wishStore.getMyWishList


      this.templateUsedIds = getUniqTemplateIdList(this.templateUsedIds, myWishes)

      const p = new Promise(async resolve => {
        // mock กันพังถ้าไม่ได้เลือกมา
        if(this.templateUsedIds.length === 0) {
          this.templateUsedIds = ['standard01', 'standard02', 'remove01', 'remove02']
        }

        const promiseList = []
        for (let index = 0; index < this.templateUsedIds.length; index++) {
          const templateId = this.templateUsedIds[index];

          const docRef = doc(db, 'templates', templateId)
          const pm = getDoc(docRef)
          promiseList.push(pm)
        }

        const pmResponses = await Promise.all(promiseList)
        
        const tmpList:any =  _map(pmResponses, (response) => response.data())
        const filtered = _filter(tmpList, t => t?.templateId)

        const mergeList = _concat(this.templateList, filtered)
        const uniqList = _uniqBy(mergeList, 'templateId')
        this.templateList = uniqList
        resolve(true)
      })

      return p
    },

    generateRandomTemplates() {
      const templateList = this.templateList
  
      const filteredTemplate = filterTemplate(templateList)
      let { standardList, removeList } = filteredTemplate
          
      this.template_1 = removeList.length > 0 ? _sample(removeList) : 'remove01'

      if(standardList.length === 0 || standardList?.length < 2) {
        standardList = [...standardList, 'standard01', 'standard02']
        standardList = _uniq(standardList)
      }

      const takeList = _take(standardList, 2) || ['standard01', 'standard02']
      this.template_2 = takeList[0]
      this.template_3 = takeList[1]

      console.log('template_1',this.template_1)
      console.log('template_2',this.template_2)
      console.log('template_3',this.template_3)
    },

    randomOneTemplateId() {
      const list = _concat(this.template_1, this.template_2, this.template_3)
      this.templateId = _sample(list)
    },

    randomNewTemplateByType(type: string) {
      const templateList = this.templateList
  
      const filteredTemplate = filterTemplate(templateList)
      const { standardList, removeList } = filteredTemplate

      let targetTemplate = ''

      if(type === 'template_1') {
        targetTemplate =  _sample(removeList)
        this.template_1 = targetTemplate
        console.log('new random template_1: ', this.template_1)
      }

      if(type === 'template_2') {
        targetTemplate = _sample(standardList)
        this.template_2 = targetTemplate
        console.log('new random template_2: ', this.template_2)
      }

      if(type === 'template_3') {
        targetTemplate = _sample(standardList)
        this.template_3 = targetTemplate
        console.log('new random template_3: ', this.template_3)
      }
    },

    selectNewTemplateByTemplateId(type: string, newTemplateId: string) {
      if(type === 'template_1') {
        this.template_1 = newTemplateId
        console.log('new template_1: ', this.template_1)
      }

      if(type === 'template_2') {
        this.template_2 = newTemplateId
        console.log('new template_2: ', this.template_2)
      }

      if(type === 'template_3') {
        this.template_3 = newTemplateId
        console.log('new template_3: ', this.template_3)
      }
    },

    async renderEditor(elementId, wishData) {
      const eventStore = useEventStore()
        const templateId = this.templateId

        const templateData = _find(this.templateList, t => t.templateId === templateId)
  
        const editorInstance = await useTemplate(elementId, templateData, wishData, {setting: null})
        const tools = await editorInstance.createStudio(true)
        // editorInstance.scaleStage(0.2)
        window.editorInstance = editorInstance
        return {
          stage: editorInstance.stage,
          tools,
        }
    },

    getImageAttrs() {
      return window.editorInstance.getGuestImageAttrs();
    },

    async renderPreview(elementId: string, wishData, templateTarget = null, attrs = null) {
      const eventStore = useEventStore()

      const templateId = templateTarget ? templateTarget : this.templateId
      const templateData = _find(this.templateList, t => t.templateId === templateId)

      let newWishData = wishData
      if(attrs) {
        newWishData.guest['imageAttrs'] = attrs
      }

      debugger
      const renderInstance = await useTemplate(elementId, templateData, newWishData, {setting: null})
      await renderInstance.createStudio(false)
      // renderInstance.scaleStage(0.2)

      return renderInstance.stage
    },

   

    // ----- for test -----
    async renderTestEditorDubug(elementId, wishData) {
      const eventStore = useEventStore()
        const templateId = this.templateId

        const templateData = _find(this.templateList, (t) => {
          return t.templateId === templateId
        })
        console.log(templateData)
  
        const editorInstance = await useTemplate(elementId, templateData, wishData, {setting: null})
        await editorInstance.createStudio(true)
        // editorInstance.scaleStage(0.7)

        return editorInstance.stage
    },
  },
  getters: {}
})

//  ----- utils -----
function getUniqTemplateIdList(templateIds: any, myWishes: any) {

  const wishedTemplateIds = _map(myWishes, w => w?.templateId)
  const mergeList = _concat(templateIds, wishedTemplateIds)

  const uniqList = _uniq(mergeList)

  return uniqList
}
