import { default as editor_copyGiGe768WUZMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor_copy.vue?macro=true";
import { default as editorzKnHlBBsBaMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor.vue?macro=true";
import { default as error4AFzhtzhUrMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/error.vue?macro=true";
import { default as _91eventId_939v14BlmvfTMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/[eventId].vue?macro=true";
import { default as index2z1LINHK7eMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/index.vue?macro=true";
import { default as eventNotFoundnhoalcYtFSMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/eventNotFound.vue?macro=true";
import { default as index064Acc9o6CMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/index.vue?macro=true";
import { default as loginEventNndmMMcsvkMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/loginEvent.vue?macro=true";
import { default as mywishnKVjlowufZMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/mywish.vue?macro=true";
import { default as test_45iframelMkba2mgyqMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-iframe.vue?macro=true";
import { default as test_45template1Oxq32XibtMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-template.vue?macro=true";
import { default as test_45transform_45wishvD9O5hjKfCMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-transform-wish.vue?macro=true";
import { default as test_45uploadsiUKLyMLTbMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-upload.vue?macro=true";
import { default as test_45wishesUJ4vXiAlXfMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-wishes.vue?macro=true";
import { default as previewp7cU0CkgClMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/preview.vue?macro=true";
import { default as attach4XqQuetkvBMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/attach.vue?macro=true";
import { default as index0zHslcha6nMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/index.vue?macro=true";
import { default as _91qrcodeId_93rshAdmOEHsMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/[qrcodeId].vue?macro=true";
import { default as indexBSmB8qesYFMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/index.vue?macro=true";
import { default as selectTemplatedFXTvfpdk6Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectTemplate.vue?macro=true";
import { default as selectUploadAndWishStkmaTWzVjMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectUploadAndWish.vue?macro=true";
import { default as thankyoutGSxuoMsuRMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/thankyou.vue?macro=true";
import { default as welcomeVVnTjEzWRuMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/welcome.vue?macro=true";
export default [
  {
    name: editor_copyGiGe768WUZMeta?.name ?? "editor_copy",
    path: editor_copyGiGe768WUZMeta?.path ?? "/editor_copy",
    meta: editor_copyGiGe768WUZMeta || {},
    alias: editor_copyGiGe768WUZMeta?.alias || [],
    redirect: editor_copyGiGe768WUZMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor_copy.vue").then(m => m.default || m)
  },
  {
    name: editorzKnHlBBsBaMeta?.name ?? "editor",
    path: editorzKnHlBBsBaMeta?.path ?? "/editor",
    meta: editorzKnHlBBsBaMeta || {},
    alias: editorzKnHlBBsBaMeta?.alias || [],
    redirect: editorzKnHlBBsBaMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor.vue").then(m => m.default || m)
  },
  {
    name: error4AFzhtzhUrMeta?.name ?? "error",
    path: error4AFzhtzhUrMeta?.path ?? "/error",
    meta: error4AFzhtzhUrMeta || {},
    alias: error4AFzhtzhUrMeta?.alias || [],
    redirect: error4AFzhtzhUrMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_939v14BlmvfTMeta?.name ?? "event-eventId",
    path: _91eventId_939v14BlmvfTMeta?.path ?? "/event/:eventId()",
    meta: _91eventId_939v14BlmvfTMeta || {},
    alias: _91eventId_939v14BlmvfTMeta?.alias || [],
    redirect: _91eventId_939v14BlmvfTMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/[eventId].vue").then(m => m.default || m)
  },
  {
    name: index2z1LINHK7eMeta?.name ?? "event",
    path: index2z1LINHK7eMeta?.path ?? "/event",
    meta: index2z1LINHK7eMeta || {},
    alias: index2z1LINHK7eMeta?.alias || [],
    redirect: index2z1LINHK7eMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: eventNotFoundnhoalcYtFSMeta?.name ?? "eventNotFound",
    path: eventNotFoundnhoalcYtFSMeta?.path ?? "/eventNotFound",
    meta: eventNotFoundnhoalcYtFSMeta || {},
    alias: eventNotFoundnhoalcYtFSMeta?.alias || [],
    redirect: eventNotFoundnhoalcYtFSMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/eventNotFound.vue").then(m => m.default || m)
  },
  {
    name: index064Acc9o6CMeta?.name ?? "index",
    path: index064Acc9o6CMeta?.path ?? "/",
    meta: index064Acc9o6CMeta || {},
    alias: index064Acc9o6CMeta?.alias || [],
    redirect: index064Acc9o6CMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginEventNndmMMcsvkMeta?.name ?? "loginEvent",
    path: loginEventNndmMMcsvkMeta?.path ?? "/loginEvent",
    meta: loginEventNndmMMcsvkMeta || {},
    alias: loginEventNndmMMcsvkMeta?.alias || [],
    redirect: loginEventNndmMMcsvkMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/loginEvent.vue").then(m => m.default || m)
  },
  {
    name: mywishnKVjlowufZMeta?.name ?? "mywish",
    path: mywishnKVjlowufZMeta?.path ?? "/mywish",
    meta: mywishnKVjlowufZMeta || {},
    alias: mywishnKVjlowufZMeta?.alias || [],
    redirect: mywishnKVjlowufZMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/mywish.vue").then(m => m.default || m)
  },
  {
    name: test_45iframelMkba2mgyqMeta?.name ?? "playground-test-iframe",
    path: test_45iframelMkba2mgyqMeta?.path ?? "/playground/test-iframe",
    meta: test_45iframelMkba2mgyqMeta || {},
    alias: test_45iframelMkba2mgyqMeta?.alias || [],
    redirect: test_45iframelMkba2mgyqMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-iframe.vue").then(m => m.default || m)
  },
  {
    name: test_45template1Oxq32XibtMeta?.name ?? "playground-test-template",
    path: test_45template1Oxq32XibtMeta?.path ?? "/playground/test-template",
    meta: test_45template1Oxq32XibtMeta || {},
    alias: test_45template1Oxq32XibtMeta?.alias || [],
    redirect: test_45template1Oxq32XibtMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-template.vue").then(m => m.default || m)
  },
  {
    name: test_45transform_45wishvD9O5hjKfCMeta?.name ?? "playground-test-transform-wish",
    path: test_45transform_45wishvD9O5hjKfCMeta?.path ?? "/playground/test-transform-wish",
    meta: test_45transform_45wishvD9O5hjKfCMeta || {},
    alias: test_45transform_45wishvD9O5hjKfCMeta?.alias || [],
    redirect: test_45transform_45wishvD9O5hjKfCMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-transform-wish.vue").then(m => m.default || m)
  },
  {
    name: test_45uploadsiUKLyMLTbMeta?.name ?? "playground-test-upload",
    path: test_45uploadsiUKLyMLTbMeta?.path ?? "/playground/test-upload",
    meta: test_45uploadsiUKLyMLTbMeta || {},
    alias: test_45uploadsiUKLyMLTbMeta?.alias || [],
    redirect: test_45uploadsiUKLyMLTbMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-upload.vue").then(m => m.default || m)
  },
  {
    name: test_45wishesUJ4vXiAlXfMeta?.name ?? "playground-test-wishes",
    path: test_45wishesUJ4vXiAlXfMeta?.path ?? "/playground/test-wishes",
    meta: test_45wishesUJ4vXiAlXfMeta || {},
    alias: test_45wishesUJ4vXiAlXfMeta?.alias || [],
    redirect: test_45wishesUJ4vXiAlXfMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-wishes.vue").then(m => m.default || m)
  },
  {
    name: previewp7cU0CkgClMeta?.name ?? "preview",
    path: previewp7cU0CkgClMeta?.path ?? "/preview",
    meta: previewp7cU0CkgClMeta || {},
    alias: previewp7cU0CkgClMeta?.alias || [],
    redirect: previewp7cU0CkgClMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: attach4XqQuetkvBMeta?.name ?? "promptpay-attach",
    path: attach4XqQuetkvBMeta?.path ?? "/promptpay/attach",
    meta: attach4XqQuetkvBMeta || {},
    alias: attach4XqQuetkvBMeta?.alias || [],
    redirect: attach4XqQuetkvBMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/attach.vue").then(m => m.default || m)
  },
  {
    name: index0zHslcha6nMeta?.name ?? "promptpay",
    path: index0zHslcha6nMeta?.path ?? "/promptpay",
    meta: index0zHslcha6nMeta || {},
    alias: index0zHslcha6nMeta?.alias || [],
    redirect: index0zHslcha6nMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/index.vue").then(m => m.default || m)
  },
  {
    name: _91qrcodeId_93rshAdmOEHsMeta?.name ?? "qr-qrcodeId",
    path: _91qrcodeId_93rshAdmOEHsMeta?.path ?? "/qr/:qrcodeId()",
    meta: _91qrcodeId_93rshAdmOEHsMeta || {},
    alias: _91qrcodeId_93rshAdmOEHsMeta?.alias || [],
    redirect: _91qrcodeId_93rshAdmOEHsMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/[qrcodeId].vue").then(m => m.default || m)
  },
  {
    name: indexBSmB8qesYFMeta?.name ?? "qr",
    path: indexBSmB8qesYFMeta?.path ?? "/qr",
    meta: indexBSmB8qesYFMeta || {},
    alias: indexBSmB8qesYFMeta?.alias || [],
    redirect: indexBSmB8qesYFMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/index.vue").then(m => m.default || m)
  },
  {
    name: selectTemplatedFXTvfpdk6Meta?.name ?? "selectTemplate",
    path: selectTemplatedFXTvfpdk6Meta?.path ?? "/selectTemplate",
    meta: selectTemplatedFXTvfpdk6Meta || {},
    alias: selectTemplatedFXTvfpdk6Meta?.alias || [],
    redirect: selectTemplatedFXTvfpdk6Meta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectTemplate.vue").then(m => m.default || m)
  },
  {
    name: selectUploadAndWishStkmaTWzVjMeta?.name ?? "selectUploadAndWish",
    path: selectUploadAndWishStkmaTWzVjMeta?.path ?? "/selectUploadAndWish",
    meta: selectUploadAndWishStkmaTWzVjMeta || {},
    alias: selectUploadAndWishStkmaTWzVjMeta?.alias || [],
    redirect: selectUploadAndWishStkmaTWzVjMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectUploadAndWish.vue").then(m => m.default || m)
  },
  {
    name: thankyoutGSxuoMsuRMeta?.name ?? "thankyou",
    path: thankyoutGSxuoMsuRMeta?.path ?? "/thankyou",
    meta: thankyoutGSxuoMsuRMeta || {},
    alias: thankyoutGSxuoMsuRMeta?.alias || [],
    redirect: thankyoutGSxuoMsuRMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: welcomeVVnTjEzWRuMeta?.name ?? "welcome",
    path: welcomeVVnTjEzWRuMeta?.path ?? "/welcome",
    meta: welcomeVVnTjEzWRuMeta || {},
    alias: welcomeVVnTjEzWRuMeta?.alias || [],
    redirect: welcomeVVnTjEzWRuMeta?.redirect,
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/welcome.vue").then(m => m.default || m)
  }
]