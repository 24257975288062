import _ from 'lodash'
import delay from '../utils/delay'

export const useTemplate = (elementId, templateData, wishData, { setting }) => {

  const base = useTemplateBase(templateData, elementId, );
  
  const render = useTemplateRender({
    ...base,
    template: templateData,
  });

  const { writeMode, readMode, getGuestImageAttrs, setGuestImageAttrs  } = useTemplateWish({
    ...base,
    template: templateData,
    wishData: wishData,
  });

  const loadingTemplate = useTemplateLoading({
    ...base,
    template: templateData,
    // wishData: wishData,
  });

  const zoomTools = useTemplateZooms({
    ...base,
    template: templateData,
  })

  async function createStudio(editorMode = false) {
    render.init()
    loadingTemplate.block()
    let imageAttrs = wishData?.guest?.imageAttrs || null
    imageAttrs = _.size(imageAttrs) == 0 ? null : imageAttrs


    
    if (editorMode) {
      writeMode(imageAttrs)
    } else {
      readMode(imageAttrs)
    }
    loadingTemplate.start()


    await delay(1)
    
    let tools = null
    if (editorMode) {
      tools = zoomTools.initZoomTools()
    }

    return tools
  }

  return {
    stage: base.stage,
    scaleStage: base.scaleStage,
    createStudio,
    
    getGuestImageAttrs,
    setGuestImageAttrs,
  }
}