import { defineStore } from 'pinia'

import _forEach from 'lodash/forEach.js'
import _filter from 'lodash/filter.js'
import _includes from 'lodash/includes.js'
import _map from 'lodash/map.js'

import { useEventStore } from './event'
import { useWishStore } from './wish'

import { ALL_FLOWS } from '~~/static/data/allFlow'
import { i18n } from '~/plugins/i18n'

export const useAppStateStore = defineStore({
  id: 'appState',
  state: () => {
    return {
      landedIndexPage: false,
      view: {},  //current view
      viewIndex: 0, //current view index
      flowLength: 0,
      flowList: [],
      flowType: 'self_service', // dslr
      locale: '',

      callback: null,

      loading: null as any,
      showModalSetLang: false,
      uploadPercent : 0,

      wishForm: null,


      toast: {
				isShow: false,
				text: '',
				color: 'bg-green-500',
				icon: 'success', // success, error, warning, info
				timeout: 3000,
				clearTime: null
			},
    }
  },
  actions: {
    async main() {
      
      this.view = this.getFlow[0]
      let flowList = this.getFlow
      
      
      const isSelfService = this.flowType == 'self_service'
    
      const flowStepTarget = isSelfService ? 'self_service_step' : 'dslr_step'
      const filteredFlow = _filter(flowList, f => f[flowStepTarget])
      this.flowList = filteredFlow
    },

    async goNext() {
       
      const $router = useRouter()
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      let nextObj = this.nextFlow

      if(this.callback) {
        const result = await this.callback()
        if(result) {
          this.view = nextObj.data
          this.viewIndex = nextObj.index


          let path = `${nextObj.data.path}?eventId=${eventId}`

          if(nextObj.data.path === '/event/') {
            path = nextObj.data.path + eventId
          }

          if(nextObj.data.path === '/qr/') {
            path = nextObj.data.path + eventStore.qrcodeId
          }

          $router.push(path)
          this.callback = null
        }
      }
    },

    async goBack() {
      const $router = useRouter()
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      let backObj = this.backFlow

      this.view = backObj.data
      this.viewIndex = backObj.index || this.viewIndex
      
      this.callback = null

      let path = `${backObj.data.path}?eventId=${eventId}`

      if(backObj.data.path === '/event/') {
        path = backObj.data.path + eventId
      }

      if(backObj.data.path === '/qr/') {
        path = backObj.data.path + eventStore.qrcodeId
      }

      $router.push(path)

    },

    setLang(targetLang: string) {
      const storageLang:any = window.localStorage.getItem('lang')

      if(storageLang) {
        i18n.global.locale.value = storageLang
        this.showModalSetLang = false
        this.locale = storageLang
      } else {
        const isThaiLang =  _includes(targetLang, 'th')

        if(!isThaiLang) {
          this.showModalSetLang = true
          return
        }

        const resultLang =  isThaiLang ? 'th' : 'en'
        i18n.global.locale.value = resultLang
        this.showModalSetLang = false
        this.locale = resultLang
      } 
    },

    async useToast({text, color, icon, timeout = 3000}) {
			this.closeToast()

			let toastData = {
				isShow: true,
				text,
				color: color || this.toast.color,
				icon: icon || this.toast.icon,
				timeout: timeout || this.toast.timeout
			}
			this.toast = toastData

			if(this.toast.clearTime != null) { 
				clearTimeout(this.toast.clearTime)
			}
			this.toast.clearTime = setTimeout(()=>{
				this.toast.isShow = false
			}, timeout)
		},
		closeToast() {
			if(this.toast.clearTime) {
				clearTimeout(this.toast.clearTime)
				this.toast.isShow = false
			}
		},
  },
  getters: {
    getFlow(state) {
      const eventStore = useEventStore()
      let flowTarget:any = []
      _forEach(ALL_FLOWS, (flow:any) => {
        if(flow[state.flowType]) {
          flowTarget.push(flow)
        }
      })

      if(eventStore.qrcodeId) {
        flowTarget = _filter(flowTarget, p => p.path !== '/event/')
      }  else {
        flowTarget = _filter(flowTarget, p => p.path !== '/qr/')
      }

      return flowTarget
    },
    nextFlow(state) {
      const viewIndex = state.viewIndex
      const flowTarget = this.getFlow

      let next = {}
      let historyPage = {}
      let indexTarget = viewIndex

      // 5 step ['welcome' , 'uploadAndWish' , 'template', 'editor', 'thankyou']
      if(state.flowType == 'self_service' && viewIndex <= 5) { 
        next = flowTarget[viewIndex + 1]
        historyPage = flowTarget[viewIndex]
        indexTarget = viewIndex + 1
      }

      // 4 step ['welcome' , 'image' , 'wish' , 'thankyou']
      if(state.flowType == 'dslr' && viewIndex <= 4) { 
        next = flowTarget[viewIndex + 1]
        historyPage = flowTarget[viewIndex]
        indexTarget = viewIndex + 1
      }

      return {
        data: next,
        history: historyPage,
        index: indexTarget
      }
    },
    backFlow(state) {
      const viewIndex = state.viewIndex

      const flowTarget = this.getFlow

      if(viewIndex > 1) {
        const back = flowTarget[viewIndex - 1]
        let indexTarget = viewIndex - 1

        return {
          data: back,
          index: indexTarget
        }
      } 
      
      return {
          data: flowTarget[1], // welcome
          index: 1
      }
    },
  }
})