import { defineStore } from 'pinia'
import { makeId } from '../utils/makeId'
import { useEventStore } from './event'

import _includes from 'lodash/includes.js'
import _sortBy from 'lodash/sortBy.js'
import _filter from 'lodash/filter.js'
import { useAppStateStore } from './appState'

const client_id: string = '1654864455'
const client_secret: string = 'be47e65da11fb1d9399038d36b6c3d47'

const liffId: string = '1654864455-LlODqmBj' //real

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => {
    return {
      userId: null as any,
      lineProfile: null as any,
      isInLineApp: false,
      isDemoApp: false
    }
  },
  actions: {
    main() {
      const p = new Promise (async resolve => {
        const $route = useRoute()
        const $router = useRouter()
        const eventStore = useEventStore()
          
        const isTestLocal = _includes(window.location.href, 'localhost')
        const isUnitTest = _includes(window.location.href, 'test=true')

        const localStorageIsDemo = window.localStorage.getItem('isDemo') == 'true'
        this.isDemoApp = localStorageIsDemo || false

        if (isUnitTest) {
          await this.unitTestLogin()
          resolve(true)
          return 
        }


        if (isTestLocal) {
          await this.devLogin()
          resolve(true)
          return 
        }

        const liffInClient = liff.isInClient()

        if(liffInClient){
          await liff.init({ liffId: liffId })
          await liff.ready.then(async () => {
            await this.lineLogin()
            this.isInLineApp = true
            resolve(true)
          })
        } 
        
        if(!liffInClient) {
          await this.anonymousLogin()
          this.isInLineApp = false
          resolve(true)
          return
        }
      })
  
      return p
    },

    lineLogin() {
      const p = new Promise(async (resolve, reject) => {
        try {
          const appStateStore = useAppStateStore()
          const response = await liff.getProfile()
          const lang = await liff.getLanguage();

          // set lang
          appStateStore.setLang(lang)

          this.userId =  response.userId
          this.lineProfile = {
            userId: response.userId,
            userPicture: response.pictureUrl,
            userDisplayName: response.displayName 
          }
  
          console.log('liff login success')
          resolve(true)
        }
        catch(error) {
          alert('auth getClientProfile -----> ERROR')
          reject()
        }
  
      })
  
      return p
    },

    async anonymousLogin() {
      const appStateStore = useAppStateStore()
      // set lang
      const lang =  window.navigator.language
      appStateStore.setLang(lang)

      const eventId = window.localStorage.getItem('eventId')
      if(!eventId) return
      
      this.eventId = eventId
      let userId = null
      userId = this.getUserIdFromLocalStorage
  
      if(!userId) {
        userId = `${makeId(9, 'ABCDEF123456789')}4`
        setUserOfEventLocalStorage(eventId, userId)
      }
  
      this.userId =  userId
      this.lineProfile = {
        userId: userId,
        userPicture: 'https://firebasestorage.googleapis.com/v0/b/wedding-wish-89585.appspot.com/o/assets%2Fothers%2Fanonymous.png?alt=media&token=2fcff083-8510-4c77-926b-8909873f0cef',
        userDisplayName: 'anonymous',
      }
  
      return
    },

    devLogin() {
      const appStateStore = useAppStateStore()

      let userId = 'mock_user_dev'//'U79cb50d879d85d2641e2ca77ff9c5816'//'U88ba704e3db3c06d77b20d6bf857a22a' //'mock_user_test'
      let pic = 'https://i.imgur.com/MaTTuup.jpeg'
      let name = ''

      // set lang
      
      const lang =  window.navigator.language
      appStateStore.setLang(lang)

      this.userId = userId
      this.lineProfile = {
        userId: userId,
        userPicture: pic,
        userDisplayName: name
      }
    },

    unitTestLogin() {
      const appStateStore = useAppStateStore()

      let userId = 'mock_unit_test'
      let pic = 'https://i.imgur.com/MaTTuup.jpeg'
      let name = ''

      // set lang
      const lang =  window.navigator.language
      appStateStore.setLang(lang)

      this.userId = userId
      this.lineProfile = {
        userId: userId,
        userPicture: pic,
        userDisplayName: name
      }
    },

    closeLiff() {
      liff.closeWindow()
    },
  },
  getters: {
    userPicture(state) {
      return state.lineProfile?.userPicture || null
    },
    userDisplayName(state) {
      return state.lineProfile?.userDisplayName || null
    },
    // todo: เอาไว้ทำอะไร
    getUserIdFromLocalStorage(state) {
      const eventId = window.localStorage.getItem('eventId')
      const usersString = window.localStorage.getItem('users')

      if(!usersString) return null

      const users = JSON.parse(usersString)
      return users[eventId]
    }
  },
})



// todo: เอาไว้ทำอะไร
const setUserOfEventLocalStorage = (eventId: string, userId: string) => {
  const usersString = window.localStorage.getItem('users')
  const users = JSON.parse(usersString)
  const assignPayload = Object.assign({}, users, {
    [eventId]: userId
  })
  let payload = assignPayload
  const payloadString = JSON.stringify(payload)
  window.localStorage.setItem('users', payloadString)
}

