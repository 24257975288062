import _ from 'lodash'

export const filterTemplate = (templateList) => {

    const tmpStandard:any = []
    const tmpRemove:any = []

    _.forEach(templateList, (template:any) => {
        if(template.removebg) {
            tmpRemove.push(template.templateId)
        } else {
            tmpStandard.push(template.templateId)
        }
    })

    const standardList = tmpStandard.length == 0 ? ['standard01', 'standard02'] : tmpStandard
    const removeList = tmpRemove.length == 0 ? ['remove01'] : tmpRemove

    return {
        standardList,
        removeList
    }
}
