<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>


<script lang="ts" setup>
  import { useAuthStore } from './store/auth'
  import { useEventStore } from './store/event'
  import { useAppStateStore } from './store/appState'
  import { useTemplateStore } from './store/template'
  import { useWishStore } from './store/wish'
  import { usePromptpay } from './store/promptpay'
  import _includes from 'lodash/includes.js'

  const $route = useRoute()
  const $router = useRouter()

  const authStore = useAuthStore()
  const appStateStore = useAppStateStore()
  const eventStore = useEventStore()
  const templateStore = useTemplateStore()
  const wishStore = useWishStore()
  const promptpayStore = usePromptpay()

  onMounted(async () => {
    const isDemo = eventStore.getIsDemoAppByQuery()
    eventStore.holderDemo = false
    if(isDemo) {
      window.localStorage.setItem('isDemo', 'true')
      eventStore.holderDemo = true
    }

    const { eventId, qrId, qrcodeId } = eventStore.getQueryAndParams()
    if(eventId) {
      window.localStorage.setItem('eventId', eventId)
    }

    if(qrId) {
      window.localStorage.setItem('qrId', qrId)
    }

    if(qrcodeId) {
      window.localStorage.setItem('qrcodeId', qrcodeId)
      const resultEventId:any = await eventStore.getEventIdByQrcodeId(qrcodeId)
      resultEventId && window.localStorage.setItem('eventId', resultEventId)
    }

    await authStore.main()
    const isSuccess = await eventStore.main()
    window.localStorage.removeItem('isDemo')
    window.localStorage.removeItem('qrcodeId')

    if(isSuccess) {
      await Promise.all([
        wishStore.main(),
        appStateStore.main(),
        promptpayStore.fetchPromptpayReceipts(),
      ])

      await templateStore.main()
    }  
  })

  function beforeCreate() {
    throw new Error("Function not implemented.");
  }


</script>


